'use strict'

const _ = require('lodash')
const coreUtils = require('santa-core-utils')

const invalidStyleId = 'txtNew'
const validStyleId = 'wp1'

const styleIdFixesByComp = {
    'wysiwyg.viewer.components.WPhoto': true
}

const isCompToFix = comp => styleIdFixesByComp[comp.componentType] && comp.styleId === invalidStyleId

function fixStyleIdForComp(comp) {
    delete comp.skin
    _.set(comp, ['styleId'], validStyleId)
}

/**
 * @exports utils/dataFixer/plugins/richTextSkinDataFixer
 * @type {{exec: exec}}
 */
module.exports = {
    exec(pageJson) {
        const desktopComps = coreUtils.dataUtils.getAllCompsInStructure(pageJson.structure, false, isCompToFix)
        const mobileComps = coreUtils.dataUtils.getAllCompsInStructure(pageJson.structure, true, isCompToFix)

        _.forEach(desktopComps, (comp, compId) => {
            const mobileComp = mobileComps[compId]
            fixStyleIdForComp(comp)
            if (mobileComp) {
                fixStyleIdForComp(mobileComp)
            }
        })
    }
}
