'use strict'

const _ = require('lodash')
const runOnAllCompsRecursively = require('../helpers/runOnAllCompsRecursively')

const MOBILE_MASTER_PAGE_VALID_COMPS = [
    'wysiwyg.viewer.components.HeaderContainer',
    'wysiwyg.viewer.components.SiteRegionContainer',
    'wysiwyg.viewer.components.PagesContainer',
    'wysiwyg.viewer.components.FooterContainer',
    'platform.components.AppController',
    'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
    'wysiwyg.viewer.components.QuickActionBar',
    'wysiwyg.common.components.backtotopbutton.viewer.BackToTopButton'
]

const hasInvalidSOAPInMobile = masterPageJson => {
    const mobileMasterPageComps = _.get(masterPageJson, ['structure', 'mobileComponents'])

    return _.some(mobileMasterPageComps, ({componentType}) => !_.includes(MOBILE_MASTER_PAGE_VALID_COMPS, componentType))
}

const isDesktopSectionsLayoutMigrated = masterPageJson => getLayoutSettings(masterPageJson).useDesktopSectionsLayout

const setLayoutMechanism = (masterPageJson, mechanism) => _.set(masterPageJson, ['data', 'document_data', 'masterPage', 'layoutSettings', 'mechanism'], mechanism)
const getLayoutMechanism = masterPageJson => _.get(masterPageJson, ['data', 'document_data', 'masterPage', 'layoutSettings', 'mechanism'], 'anchors')
const getLayoutSettings = masterPageJson => _.get(masterPageJson, ['data', 'document_data', 'masterPage', 'layoutSettings'], {})

const isDesktopMasterPageReadyToMesh = masterPageJson => isDesktopSectionsLayoutMigrated(masterPageJson)
const isMobileMasterPageReadyToMesh = masterPageJson => !hasInvalidSOAPInMobile(masterPageJson)

const rejectMeshIfNeeded = masterPageJson => {
    if (getLayoutMechanism(masterPageJson) === 'mesh' && (!isDesktopMasterPageReadyToMesh(masterPageJson) || !isMobileMasterPageReadyToMesh(masterPageJson))) {
        setLayoutMechanism(masterPageJson, 'anchors')
    }
}

function fixMasterPage(masterPageDocumentData) {
    if (masterPageDocumentData.SITE_STRUCTURE) {
        masterPageDocumentData.masterPage = masterPageDocumentData.SITE_STRUCTURE
    }
    if (!masterPageDocumentData.masterPage) {
        masterPageDocumentData.masterPage = {}
    }
    masterPageDocumentData.masterPage.id = 'masterPage'
    delete masterPageDocumentData.SITE_STRUCTURE
}

function hasCorruptedWidth(comp) {
    return _.get(comp, 'layout.width') === 0
}

function hasCorruptedHeight(comp) {
    return _.get(comp, 'layout.height') === 0
}

function setDefaultSizesForCorruptedComponents(comp) {
    if (hasCorruptedWidth(comp)) {
        _.set(comp, 'layout.width', 100)
    }
    if (hasCorruptedHeight(comp)) {
        _.set(comp, 'layout.height', 100)
    }
}

function fixAnchorDistances(children) {
    _.forEach(children, child => {
        _.forEach(_.get(child, 'layout.anchors'), anchor => {
            switch (child.id) {
                case 'SITE_FOOTER':
                    if (anchor.type === 'BOTTOM_PARENT') {
                        anchor.distance = 0
                    }
                    break

                case 'PAGES_CONTAINER':
                    if (anchor.type === 'BOTTOM_TOP' && anchor.locked && anchor.distance >= 70) {
                        anchor.originalValue = 0
                        anchor.locked = false
                    }
                    break
            }
        })
    })
}

function fixCorruptedMasterPageComponentLayouts(masterPageChildren, siteWidthForHeaderAndFooter) {
    let hasCorruption = false

    _.forEach(['SITE_HEADER', 'SITE_FOOTER'], id => {
        const comp = _.find(masterPageChildren, {id})
        if (hasCorruptedWidth(comp)) {
            hasCorruption = true
        }
        if (hasCorruptedHeight(comp)) {
            _.set(comp, 'layout.height', 100)
            hasCorruption = true
        }

        _.set(comp, 'layout.width', siteWidthForHeaderAndFooter)
    })
    if (hasCorruption) {
        const rootCompsOnMasterPage = _.reject(masterPageChildren, {id: 'PAGES_CONTAINER'})
        runOnAllCompsRecursively(rootCompsOnMasterPage, [setDefaultSizesForCorruptedComponents])
    }
}

function headerHasScrubBehavior(masterPageJson, mobileHeader) {
    if (!mobileHeader.behaviorQuery) {
        return false
    }
    const behavior = _.get(masterPageJson, ['data', 'behaviors_data', mobileHeader.behaviorQuery])
    try {
        const behaviorItems = JSON.parse(behavior.items)
        return _.some(behaviorItems, {type: 'scrub', name: 'ScrubAnimation'})
    } catch (e) {
        return false
    }
}

function fixMobileFixedPositionSiteSegments(mobileMasterPageComps, masterPageJson, isFixedMobileHeaderOpen) {
    const mobileHeader = _.find(mobileMasterPageComps, {id: 'SITE_HEADER'})
    if (!mobileHeader) {
        return
    }
    if (!isFixedMobileHeaderOpen) {
        _.set(mobileHeader, ['layout', 'fixedPosition'], false)
    } else if (headerHasScrubBehavior(masterPageJson, mobileHeader)) {
        _.set(mobileHeader, ['layout', 'fixedPosition'], true)
    }

    const mobileFooter = _.find(mobileMasterPageComps, {id: 'SITE_FOOTER'})
    _.set(mobileFooter, ['layout', 'fixedPosition'], false)
}

function fixMasterPageStructure(masterPageJson, isFixedMobileHeaderOpen) {
    const masterPageStructure = masterPageJson.structure
    const desktopMasterPageComps = masterPageStructure.children
    const mobileMasterPageComps = masterPageStructure.mobileComponents
    const siteWidthForHeaderAndFooter = _.get(masterPageJson, 'data.document_data.masterPage.renderModifiers.siteWidth', 980)
    fixMissingMasterPagePartsIfNeeded(desktopMasterPageComps)
    fixCorruptedMasterPageComponentLayouts(desktopMasterPageComps, siteWidthForHeaderAndFooter)
    fixMissingMasterPagePartsIfNeeded(mobileMasterPageComps)
    fixCorruptedMasterPageComponentLayouts(mobileMasterPageComps, 320)
    fixMobileFixedPositionSiteSegments(mobileMasterPageComps, masterPageJson, isFixedMobileHeaderOpen)
    fixAnchorDistances(mobileMasterPageComps)
}

const mandatoryMasterPageParts = [
    {componentType: 'wysiwyg.viewer.components.PagesContainer', id: 'PAGES_CONTAINER'},
    {componentType: 'wysiwyg.viewer.components.FooterContainer', id: 'SITE_FOOTER'},
    {componentType: 'wysiwyg.viewer.components.HeaderContainer', id: 'SITE_HEADER'}
]

function fixMissingMasterPagePartsIfNeeded(comps) {
    _.forEach(mandatoryMasterPageParts, mandatoryPart => {
        const partByID = _.find(comps, {id: mandatoryPart.id})
        if (!partByID) {
            const partByType = _.find(comps, {componentType: mandatoryPart.componentType})
            if (partByType) {
                partByType.id = mandatoryPart.id
            }
        }
    })
}

/**
 * @exports utils/dataFixer/plugins/masterPageFixer
 * @type {{exec: exec}}
 */
module.exports = {
    exec(pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel, magicObject) {
        if (pageJson.structure && pageJson.structure.type === 'Document') {
            pageJson.structure.id = 'masterPage'
            pageJson.structure.componentType = 'mobile.core.components.MasterPage'
            fixMasterPage(pageJson.data.document_data)
            const isFixedMobileHeaderOpen = magicObject.isExperimentOpen('sv_fixedMobileHeader')
            fixMasterPageStructure(pageJson, isFixedMobileHeaderOpen)
            rejectMeshIfNeeded(pageJson)
        }
        return pageJson
    }
}